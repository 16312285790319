<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div class="responsive-bar">
      <Footer />
      <div>
        <div class="back-icon">
          <img
            src="../../assets/previous.svg"
            width="30"
            @click="$router.back()"
          />
        </div>
        <h1 class="header-text">
          <div class="topic">
            {{ planDetail.detail.name }}
          </div>
        </h1>
        <div class="container my-3" style="max-width: 500px;">
          <p class="text-start">
            <b>{{ $t("maintenance_plan.list") }}</b>
          </p>
          <div
            v-for="round in planRoundList"
            :key="round.round_date"
            class="plan-card text-start"
            @click="gotoPlanDetail(round.round_date)"
          >
            <div>
              <img
                src="@/assets/calendar-gradient-event.svg"
                class="mx-2"
                alt=""
              />
            </div>
            <div class="w-100">
              <p>
                <b>{{ round.detail.name }}</b>
              </p>
              <div class="row">
                <div class="col-4">
                  <p>{{ $t("maintenance_plan.start") }}</p>
                </div>
                <div class="col-8">
                  <p>
                    {{
                      $t("maintenance_plan.date_time", {
                        date: moment(round.round_date).format("DD/MM/YYYY"),
                        time: moment(round.round_date).format("HH:mm"),
                      })
                    }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <p>{{ $t("maintenance_plan.vendor") }}</p>
                </div>
                <div class="col-8">
                  <p>{{ round.detail.vendor.name }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <p>{{ $t("maintenance_plan.asset_amount") }}</p>
                </div>
                <div class="col-8">
                  <p>
                    {{ round.count }}
                    {{ $t("maintenance_plan.asset") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="line-break"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import moment from "moment";
import Loading from "vue-loading-overlay";

export default {
  components: { Footer, Loading },
  data() {
    return {
      isLoading: false,
      planId: null,
      id_company: null,
      planDetail: {},
      planRoundList: [],
    };
  },
  methods: {
    moment,
    gotoPlanDetail(date) {
      localStorage.setItem("round_date", date);
      this.$router.push({
        name: "MaintenancePlanDetail",
        params: {
          page: "plan",
          id: this.planId,
        },
      });
    },
    async loadPlan() {
      this.isLoading = true;
      const plan = await this.$store.dispatch("loadPlanRoundList", {
        id_company: this.id_company,
        planId: this.planId,
      });
      console.log("plan", plan);
      this.planDetail = plan.plan;
      this.planRoundList = plan.round;
      this.isLoading = false;
    },
  },
  async mounted() {
    this.planId = this.$route.params.planId;
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.loadPlan();
  },
};
</script>

<style lang="scss" scoped>
.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

.plan-card {
  display: flex;
  cursor: pointer;
  min-height: 150px;
  width: 100%;
  margin: 20px auto;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  .row {
    p {
      color: #7c7c7c;
    }
  }
}
</style>
